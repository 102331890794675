






















































































import { computed, defineComponent, PropType } from '@nuxtjs/composition-api';
import { SfPrice } from '@storefront-ui/vue';
import { getPrice } from '~/modules/catalog/product/getters/productGetters';
import { useCustomPrice, useVariantBottleInfo } from '@theme/composables';
import {
  ProductWithCommonProductCardProps
} from '~/modules/catalog/category/components/views/useProductsWithCommonCardProps';
import type { ConfigurableVariant } from '~/composables';

export default defineComponent({
  name: 'CategoryProductPrice',
  components: {
    SfPrice,
  },
  props: {
    variant: {
      type: [Object, null] as PropType<ConfigurableVariant | null>,
      default: null,
    },
    product: {
      type: Object as PropType<ProductWithCommonProductCardProps>,
      required: true,
    },
  },
  setup(props) {
    const variantPrice = computed(() => getPrice(props.variant?.product));
    const { gridBottleInfo } = useVariantBottleInfo(props.variant?.product);

    const {
      hasCustomPrice,
      getCustomPrice,
      getCustomSpecialPrice,
    } = useCustomPrice(props.variant?.product || props.product);
    const hasProductCustomPrice = computed(() => hasCustomPrice());

    return {
      variantPrice,
      hasProductCustomPrice,
      gridBottleInfo,
      getCustomPrice,
      getCustomSpecialPrice,
    }
  }
});

